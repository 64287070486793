import React, { useState } from 'react'
import AuthLayout from 'layouts/AuthLayout/AuthLayout'
import Button from 'components/elements/Button/Button'
import Input from 'components/elements/Input/Input'
import { Formik, Form } from 'formik'
import * as yup from 'yup'
import './RegisterComponent.scss'

const RegisterComponent = (props) => {
	const { error, submit, loading, history } = props

	return (
		<AuthLayout>
			<div className='register-component'>
				<h1>Register</h1>
				<Formik
					initialValues={{ name: '', email: '', password: '', confirmPassword: '' }}
					validationSchema={yup.object().shape({
						name: yup.string().required('Name is a required field'),
						email: yup.string().email('Invalid email format').required('Email is a required field'),
						password: yup.string().min(8).required('Password is a required field'),
						confirmPassword: yup
							.string()
							.oneOf([yup.ref('password'), null], 'Passwords must match')
							.required('Confirm password is a required field'),
					})}
					onSubmit={(values) => submit(values)}>
					{(formik) => (
						<>
							<Form>
								<Input
									id='name'
									formik={formik}
									type='text'
									label='Name*'
									placeholder='Insert your complete name'
								/>
								<Input
									id='email'
									formik={formik}
									type='text'
									label='Email*'
									placeholder='Insert your e-mail address'
								/>
								<Input
									id='password'
									formik={formik}
									type='password'
									label='Password*'
									placeholder='Insert your password'
								/>
								<Input
									id='confirmPassword'
									formik={formik}
									type='password'
									label='Confirm password*'
									placeholder='Confirm your password'
								/>

								{error ? <div className='error-message-global'>{error}</div> : null}

								<Button loading={loading} type='submit' onClick={formik.handleSubmit}>
									Register
								</Button>

								<span className='separator'>or</span>

								<Button className='secondary' type='button' onClick={() => history.push('/login')}>
									Back to login
								</Button>
							</Form>
						</>
					)}
				</Formik>
			</div>
		</AuthLayout>
	)
}

export default RegisterComponent
