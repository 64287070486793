import React from 'react'
import './Input.scss'

const InputComponent = (props) => {
	const { type, label, id, className, formik, placeholder } = props
	let formikProps = {}

	if (formik) {
		formikProps = {
			value: formik.values[id],
			onBlur: () => formik.setFieldTouched(id),
			onChange: (e) => formik.setFieldValue(id, e.target.value),
			error: formik.touched[id] && formik.errors[id],
		}
	}

	return (
		<div class={`input-wrapper ${formikProps.error ? 'error' : ''}`}>
			<label htmlFor={id}>{label}</label>
			{(type === 'text' || type === 'password') && (
				<input
					{...props}
					type={type}
					id={id}
					name={id}
					className={`${className} ${formikProps.error ? 'error' : ''}`}
					onBlur={formikProps.onBlur}
					onChange={formikProps.onChange}
					value={formikProps.value}
					placeholder={placeholder}
				/>
			)}
			{formikProps.error && <span class='error-message'>{formikProps.error}</span>}
		</div>
	)
}

export default InputComponent
