import React, { useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { updateUser } from 'redux/actions/userActions'
import { Link, withRouter } from 'react-router-dom'
import OutsideClickHandler from 'react-outside-click-handler'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch, faChevronDown, faSignOutAlt } from '@fortawesome/free-solid-svg-icons'
import { faStar, faCommentDots, faUser } from '@fortawesome/free-regular-svg-icons'
import './AuthLayout.scss'

const AuthLayout = (props) => {
	const { children } = props

	return (
		<div className='auth-layout'>
			<div className='background-image'>
				<img src={require('assets/images/auth-bg.jpg')} alt='' />
				<div className='background-image__overlay'></div>
			</div>
			<div className='auth-layout__card'>
				<img className='app-logo' src={require('assets/images/ptg-logo.png')} alt='' />
				{children}
			</div>
		</div>
	)
}

export default AuthLayout
