import React from 'react'
import { Provider } from 'react-redux'
import { store } from './redux/store'
import { BrowserRouter as Router, Switch } from 'react-router-dom'
import history from './services/history'

// Router Components
import PublicRoute from 'components/elements/PublicRoute/PublicRoute'
import PrivateRoute from 'components/elements/PrivateRoute/PrivateRoute'

// Auth Routes
import Login from './components/Login/containers/Login'
import Register from './components/Register/containers/Register'

// Dashboard (all users) Routes
import Home from './components/Home/containers/Home'

// Admin Routes
import CustomPage from './components/Admin/CustomPage/containers/CustomPage'

import './App.scss'

function App() {
	return (
		<Provider store={store}>
			<Router history={history}>
				<div className='App'>
					<Switch>
						<PublicRoute restricted component={Login} path='/' exact />
						<PublicRoute restricted component={Login} path='/login' />
						<PublicRoute restricted component={Register} path='/register' />

						{/* Common private Routes */}
						<PrivateRoute component={Home} path='/home' />

						{/* Admin Routes */}
						<PrivateRoute role='Admin' component={CustomPage} path='/admin/custom-page' />
					</Switch>
				</div>
			</Router>
		</Provider>
	)
}

export default App
