import React from 'react'
import './Button.scss'
import Lottie from 'react-lottie'
import animationData from 'assets/lotties/loading-white.json'

export interface Props {
	children: React.ReactNode
	onClick: () => void
	disabled: boolean
	type?: 'button' | 'submit' | 'reset' | undefined
	loading?: boolean
	className?: string
	secondary?: boolean
	medium?: boolean
	small?: boolean
	text: boolean
	icon: React.ReactElement
}

const ButtonComponent: React.FC<Props> = (props) => {
	const {
		children,
		onClick,
		disabled,
		type,
		loading,
		className,
		secondary,
		medium,
		small,
		text,
		icon,
	} = props
	return (
		<button
			className={`button-component ${secondary && 'secondary'} ${disabled ? 'disable' : ''} ${
				className || ''
			}`}
			onClick={() => onClick && onClick()}
			disabled={disabled || loading}
			type={type}>
			{loading ? (
				<div className='button-component-loading'>
					<Lottie options={defaultOptions} height={40} width={40} />
				</div>
			) : (
				<div className='button-content'>{children}</div>
			)}
		</button>
	)
}

const defaultOptions = {
	loop: true,
	autoplay: true,
	animationData: animationData,
	rendererSettings: {
		preserveAspectRatio: 'xMidYMid slice',
	},
}

export default ButtonComponent
