import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import _ from 'lodash'

const PublicRoute = ({ component: Component, restricted, user, ...rest }) => {
	const isLogged = !_.isEmpty(user)
	const roleRoute = user.role === 'Admin' ? '/admin/custom-page' : '/home'

	// restricted = true meaning restricted route when logged in
	return (
		<Route
			{...rest}
			render={(props) =>
				isLogged && restricted ? <Redirect to={roleRoute} /> : <Component {...props} />
			}
		/>
	)
}

const mapStateToProps = (store) => ({
	user: store.userReducer.user,
})

export default connect(mapStateToProps)(PublicRoute)
