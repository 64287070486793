import React from 'react'
import DashboardLayout from 'layouts/DashboardLayout/DashboardLayout'
import Card from 'components/elements/Card/Card'
import './HomeComponent.scss'

const HomeComponent = (props) => {
	return (
		<DashboardLayout>
			<div className='home-wrapper'>
				<Card className='center-content'>
					<img src={require('assets/images/ptg-logo.png')} alt='ptg-logo' />
					<h1>Welcome to PTG React Dashboard Boilerplate</h1>
					<p>This boilerplate includes the following features:</p>
					<ul>
						<li>SCSS integrated and designSystem (color variables);</li>
						<li>Layouts components (AuthLayout and DashboardLayout);</li>
						<li>
							Common components: button with lots of options already integrated and simple card
							example;
						</li>
						<li>Common utility libraries: moment and lodash;</li>
						<li>Login view and logic;</li>
						<li>Register view and logic;</li>
						<li>
							Routing auth workflow as suggested by react-router documentation (
							<a href='https://reactrouter.com/web/example/auth-workflow' target='_blank'>
								https://reactrouter.com/web/example/auth-workflow
							</a>
							);
						</li>
						<li>Redux with User Reducer;</li>
						<li>
							Formik, Yup and a Input with formik integration examples (Login and Register
							components);
						</li>
						<li>
							FontAwesome installed and with usage examples. Icons library:{' '}
							<a href='https://fontawesome.com/icons?d=gallery' target='_blank'>
								https://fontawesome.com/icons?d=gallery
							</a>
							;
						</li>
						<li>Helpers / Services (user, history, endpoints and customFetch);</li>
						<li>
							Using jsconfig.json to avoid relative paths (current absolute path is now set to
							/src).
						</li>
					</ul>
				</Card>
			</div>
		</DashboardLayout>
	)
}

export default HomeComponent
