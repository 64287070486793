import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import _ from 'lodash'

const PrivateRoute = ({ component: Component, user, role, ...rest }) => {
	const isLogged = !_.isEmpty(user)
	const userRole = user && user.role
	const roleMatch = role ? userRole === role : true

	return (
		<Route
			{...rest}
			render={(props) =>
				isLogged && roleMatch ? <Component {...props} /> : <Redirect to='/login' />
			}
		/>
	)
}

const mapStateToProps = (store) => ({
	user: store.userReducer.user,
})

export default connect(mapStateToProps)(PrivateRoute)
