import { endpoints } from './endpoints'
import customFetch from './customFetch'
import { getUserToken } from './utils'

// Logins factory
const existingLogins = [
	{
		id: 1,
		name: 'Admin',
		email: 'admin@pitagorinesgroup.com',
		password: 'admin',
		role: 'Admin',
	},
	{
		id: 2,
		name: 'User',
		email: 'user@pitagorinesgroup.com',
		password: 'user',
		role: 'User',
	},
]

const authFactory = (credentials) => {
	const userExists = existingLogins.filter(
		(existingLogin) => existingLogin.email === credentials.email
	)[0]

	if (userExists) {
		return {
			...userExists,
			token: '123',
		}
	} else {
		throw 'Wrong credentials'
	}
}

export const loginService = async (credentials) => {
	return authFactory(credentials) // Remove this line when integrating with back-end

	try {
		let response = await customFetch(`${endpoints.user}/login`, {
			method: 'POST',
			bodyReq: credentials,
		})

		if (response.error) throw response

		const { id } = await getUserIdService(response.token)
		const userInfo = await getUserInfoService(id, response.token)

		return { ...userInfo, token: response.token }
	} catch (err) {
		throw err
	}
}

export const registerService = async (credentials) => {
	return authFactory(credentials) // Remove this line when integrating with back-end

	try {
		let response = await customFetch(`${endpoints.user}/register`, {
			method: 'POST',
			bodyReq: credentials,
		})

		if (response.error) throw response

		const { id } = await getUserIdService(response.token)
		const userInfo = await getUserInfoService(id, response.token)

		return { ...userInfo, token: response.token }
	} catch (err) {
		throw err
	}
}

export const getUserIdService = async (token) => {
	try {
		let response = await customFetch(`${endpoints.user}/me`, {
			method: 'GET',
			token,
		})

		if (response.error) throw response

		return response
	} catch (err) {
		throw err
	}
}

export const getUserInfoService = async (id, token) => {
	try {
		let response = await customFetch(`${endpoints.user}/${id}`, {
			method: 'GET',
			token,
		})

		if (response.error) throw response

		return response
	} catch (err) {
		throw err
	}
}
