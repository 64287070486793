import React, { useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { updateUser } from 'redux/actions/userActions'
import { Link, withRouter } from 'react-router-dom'
import OutsideClickHandler from 'react-outside-click-handler'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faSignOutAlt, faHome, faUserLock } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'
import './DashboardLayout.scss'

const DashboardLayout = (props) => {
	const { user, updateUser } = props
	const [isDropdownOpen, setIsDropdownOpen] = useState(false)

	const userRoutes = [
		{
			icon: <FontAwesomeIcon icon={faHome} />,
			label: 'Home',
			routeLink: '/home',
		},
	]

	const adminRoutes = [
		{
			icon: <FontAwesomeIcon icon={faUserLock} />,
			label: 'Admin Page',
			routeLink: '/admin/custom-page',
		},
	]

	const routes = user.role === 'Admin' ? [...userRoutes, ...adminRoutes] : [...userRoutes]

	const {
		children,
		location: { pathname },
	} = props
	const currentPath = pathname

	const username = user.name
	const userphoto = user.urlProfileImage

	const logout = () => {
		updateUser({})
		localStorage.clear()
	}

	return (
		<div className='dashboard-layout'>
			<div className='sidebar'>
				<div className='logo'>
					<img src={require('assets/images/ptg-logo-white.png')} alt='logo-full' />
				</div>

				{routes.map((route, i) => (
					<Link
						to={route.routeLink}
						key={i}
						className={`sidebar-item ${route.routeLink.includes(currentPath) ? 'active' : null}`}>
						<div className='sidebar-icon'>{route.icon}</div>
						<div className='sidebar-label'>{route.label}</div>
					</Link>
				))}
			</div>

			<div className='content'>
				<div className='top-bar'>
					<div className='greetings'>
						<h3>Welcome, {username}</h3>
						<p>Today is {moment().format('LLLL')}</p>
					</div>

					<div className='logged-user-info'>
						<OutsideClickHandler onOutsideClick={(e) => setIsDropdownOpen(false)}>
							<div
								className='logged-user-info__content'
								onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
								<img
									src={userphoto || require('assets/images/user-placeholder.jpg')}
									alt='user-placeholder'
								/>
								<span title={username}>{username}</span>
								<FontAwesomeIcon icon={faChevronDown} />
							</div>

							{isDropdownOpen && (
								<div className='logged-user-info__options'>
									<div className='option' onClick={() => logout()}>
										<FontAwesomeIcon icon={faSignOutAlt} />
										<span>Logout</span>
									</div>
								</div>
							)}
						</OutsideClickHandler>
					</div>
				</div>
				<div className='content-children'>{children}</div>
			</div>
		</div>
	)
}

const mapStateToProps = (store) => ({
	user: store.userReducer.user,
})

const mapDispatchToProps = (dispatch) => bindActionCreators({ updateUser }, dispatch)

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DashboardLayout))
