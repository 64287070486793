import React from 'react'
import { Formik, Form } from 'formik'
import * as yup from 'yup'
import AuthLayout from 'layouts/AuthLayout/AuthLayout'
import Button from 'components/elements/Button/Button'
import Input from 'components/elements/Input/Input'
import './LoginComponent.scss'

const LoginComponent = (props) => {
	const { loading, submit, error, history } = props

	return (
		<AuthLayout>
			<div className='login-component'>
				<h1>Login</h1>
				<Formik
					initialValues={{ email: '', password: '' }}
					validationSchema={yup.object().shape({
						email: yup.string().email('Invalid email format').required('Email is a required field'),
						password: yup.string().required('Password is a required field'),
					})}
					onSubmit={(values) => submit(values)}>
					{(formik) => (
						<>
							<Form>
								<Input
									id='email'
									formik={formik}
									type='text'
									label='Email*'
									placeholder='Insert your e-mail address'
								/>
								<Input
									id='password'
									formik={formik}
									type='password'
									label='Password*'
									className='formControl'
									placeholder='Insert your password'
								/>

								<a href='#' className='forgot-password'>
									Forgot password?
								</a>

								{error ? (
									<div className='error-message-global'>Wrong credentials. Please try again.</div>
								) : null}

								<Button loading={loading} type='submit' onClick={formik.handleSubmit}>
									Login
								</Button>

								<span className='separator'>or</span>

								<Button
									className='secondary'
									type='button'
									onClick={() => history.push('/register')}>
									Register
								</Button>
							</Form>
						</>
					)}
				</Formik>
			</div>
		</AuthLayout>
	)
}

export default LoginComponent
